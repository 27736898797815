<template>
  <select :aria-label="$t('accessibility.language_switcher')"
          :class="{'large' : large == true , 'dark' : dark == true, 'bikeable': platform == 'bikeable', 'walkable': platform == 'walkable'}"
          v-if="languages.length > 1"
          @change="setLocale($event)"
          v-model="locale">
    <option v-for="language in languages"
            :value="language">
      {{ language.toUpperCase() }}
    </option>
  </select>
</template>
<script setup lang="ts">

const { large = false, dark = false } = defineProps<{ large?: boolean, dark?: boolean }>();
const platformSettings = usePlatformSettings();
const languages = platformSettings.availableLocales();
const locale = platformSettings.locale;
const platform = platformSettings.platformName;
const auth = useAuth();

function setLocale(event: Event) {
  const lang = (event.target as HTMLSelectElement).value.toLowerCase();
  platformSettings.setLocale(lang);

  if (auth.authenticated.value) {
    auth.updateLocale(lang);
  }
}

</script>
<style lang="scss" scoped>
select {
  @apply h-[34px] text-base text-theme-secondary bg-theme-bg border border-theme-secondary rounded-[7px] pt-0 pb-0 pl-1 pr-[30px] appearance-none bg-[url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")] bg-no-repeat;
  background-position: right 0.5rem top 50%;
  background-size: 0.6rem auto;
}

select.large {
  @apply h-[44px] text-xl pl-2 pr-3 pt-1 pb-1;
  background-position: right 1rem top 50%;
  background-size: 0.65rem auto;
}

select.bikeable {
  @apply bg-[url('@/assets/icon/select-arrow-bikeable.svg')];
}

select.walkable {
  @apply bg-[url('@/assets/icon/select-arrow-walkable.svg')];

}

select.bikeable.dark {
  @apply bg-theme-font text-white bg-[url('@/assets/icon/select-arrow-white.svg')];

}

select.walkable.dark {
  @apply bg-theme-font text-white bg-[url('@/assets/icon/select-arrow-white.svg')];
}

</style>
